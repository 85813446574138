export default function (item: any, currencyKey: any) {
  let unitPrice = null

  if (currencyKey === 'vi') {
    unitPrice = item?.unit_price_vnd ?? null
  }
  else if (currencyKey === 'en') {
    unitPrice = item?.unit_price_usd ?? null
  }
  else if (currencyKey === 'eu') {
    unitPrice = item?.unit_price_eur ?? null
  }

  if (!unitPrice) {
    unitPrice = item?.unit_price ?? 0
  }

  return unitPrice * 1
}
